(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
window.Void = function() { }; (function($) { $.fn.voidLink = function() { return this.attr('href', 'javascript:Void()'); }; } (jQuery));
var Site = window.Site || {};
(function($) {

    //$.blockUI.defaults.css.cursor = "auto";

    $.stringFormat = function(str, args) {
        for (var i = 0; i < args.length; i++) {
            if (typeof args[i] !== "undefined") {
                str = str.replace("{" + i + "}", args[i]);
            } else {
                str = str.replace("{" + i + "}", "");
            }
        }

        return str;
    }

    Site.Init = function() {
        Site.MobileNav.Init();
        Site.ProductQuantity.Init();
        // Site.PromoCodeResponse.Init();
        // Site.CountryDropdown.Init();
        //Site.Validation.Init(); //should be last
    };


    /* Mobile menu
    *
    * @author Danielle Rivers
    * @memberOf Site
    * @namespace
    * @requires jQuery
    */

    Site.MobileNav = {
        Init: function() {
            $('.mobileNavTrigger').on('click', function(e){
                $('header.mobileHeader ul').slideToggle();
                e.preventDefault();
            });
            if($(window).width() <= 1024) {
                $('header').addClass('mobileHeader');
                $('header').removeClass('desktopHeader');
            }
            if($(window).width() > 1024) {
                $('header').addClass('desktopHeader');
                $('header').removeClass('mobileHeader');
            }

            $(window).resize(function(){
                if($(window).width() < 1024) {
                    $('header').addClass('mobileHeader');
                    $('header').removeClass('desktopHeader');
                }
                if($(window).width() > 1024) {
                    $('header').addClass('desktopHeader');
                    $('header').removeClass('mobileHeader');
                }
            });
        }
    };
    Site.ProductQuantity = {
        Init: function() {
                    // This button will increment the value
            $('.qtyMore').click(function(e){
                // Stop acting like a button
                e.preventDefault();
                // Get the field name
                fieldName = $(this).attr('field');
                // Get its current value
                var currentVal = parseInt($('input[name='+fieldName+']').val());
                // If is not undefined
                if (!isNaN(currentVal)) {
                    // Increment
                    $('input[name='+fieldName+']').val(currentVal + 1);
                } else {
                    // Otherwise put a 0 there
                    $('input[name='+fieldName+']').val(0);
                }
            });
            // This button will decrement the value till 0
            $(".qtyLess").click(function(e) {
                // Stop acting like a button
                e.preventDefault();
                // Get the field name
                fieldName = $(this).attr('field');
                // Get its current value
                var currentVal = parseInt($('input[name='+fieldName+']').val());
                // If it isn't undefined or its greater than 0
                if (!isNaN(currentVal) && currentVal > 0) {
                    // Decrement one
                    $('input[name='+fieldName+']').val(currentVal - 1);
                } else {
                    // Otherwise put a 0 there
                    $('input[name='+fieldName+']').val(0);
                }
            });
        }
    }


    $(Site.Init);
} (jQuery));

}).call(this,require("rH1JPG"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_2c6ddd46.js","/")